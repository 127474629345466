


























































import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, ref, Ref, watch } from '@vue/composition-api';
import { VideoWatch } from '../../services/api/video-watch.js';
import { ICameraChannel, ICameraLocation } from '../../types.js';
import shaka from 'shaka-player';
import router from '../../router/index.js';
import { isMobile } from 'mobile-device-detect';
import { BasicSelect } from 'vue-search-select';

export default defineComponent({
  name: 'VideoList',
  components: { BasicSelect },
  props: ['cameras', 'id', 'layoutProp'],
  setup(props) {
    const camerasDiv: Ref<HTMLElement | null> = ref(null);
    const layoutList = computed(() => [1, 0.5, 0.33, 0.25, 0.16].map(l => ({ text: `${l * 100}%`, value: l })));
    const layout = ref(layoutList.value[0]);
    const data: {
      cameras?: ICameraLocation,
      shaka: any,
    } = reactive({
      cameras: props.cameras,
      shaka: [],
    });

    const select = (channelId: number, replace: boolean) => {
      if (!replace) {
        router.push({
          name: 'video-watcher',
          params: {
            cameras: data.cameras as any,
            id: props.id,
            channelId: channelId.toString(),
            layout: layout.value as any,
          },
        });
      } else {
        router.replace({
          name: 'video-watcher',
          params: {
            cameras: data.cameras as any,
            id: props.id,
            channelId: channelId.toString(),
            layout: layout.value as any,
          },
        });
      }
    };

    const initPlayers = () => {
      if (data.cameras && data.cameras.channels.length > 1 && !isMobile) {
        data.cameras.channels.forEach((channel, i) => {
          const player = document.getElementById(`player-${channel.id}`) as HTMLVideoElement;
          data.shaka[i] = new shaka.Player(player);
          play(channel, player, data.shaka[i]);
        });

        if (props.layoutProp) {
          layout.value = props.layoutProp;
        } else if (data.cameras.channels.length === 1) {
          layout.value = layoutList.value[0];
        } else if (data.cameras.channels.length >= 2 && data.cameras.channels.length <= 4) {
          layout.value = layoutList.value[1];
        } else if (data.cameras.channels.length >= 5 && data.cameras.channels.length < 10) {
          layout.value = layoutList.value[2];
        } else if (data.cameras.channels.length >= 10) {
          layout.value = layoutList.value[3];
        }
      } else if (data.cameras && (data.cameras.channels.length === 1 || isMobile)) {
        select(data.cameras.channels[0].id, true);
      }
    }; ;

    onMounted(() => {
      initPlayers();
    });

    onBeforeUnmount(() => {
      data.shaka.forEach((shaka: any) => shaka.unload());
    });

    const initShaka = () => {
      if (!(window as any).shaka_init) {
        shaka.polyfill.installAll();
        if (shaka.Player.isBrowserSupported()) {
          (window as any).shaka_init = true;
        } else {
          (window as any).shaka_init = false;
          alert('Ваш браузер не поддерживает воспроизведение потокового видео. Пожалуйста, обновите браузер');
        }
      }
    };
    const play = (channel: ICameraChannel, player: HTMLVideoElement, shaka: any) => {
      if (data.cameras) {
        const url = data.cameras.channels.length > 2 ? channel.sub_stream_url : channel.stream_url;

        shaka.unload();
        shaka
          .load(url)
          .then(() => {
            player.play();
          });
      }
    };

    const getCameras = async() => {
      const { videocams } = await VideoWatch.list({
        all: true,
        municipalityId: undefined,
        id: props.id,
      });

      data.cameras = videocams[0];

      nextTick()
        .then(() => initPlayers());
    };

    if (!props.cameras) {
      getCameras();
    }

    initShaka();

    watch(props, () => {
      getCameras();
    });

    return {
      data,
      play,
      select,
      layout,
      layoutList,
      camerasDiv,
      close() {
        router.push({
          name: 'main-map',
        });
      },
      selectLayout(l: {
        text: string,
        value: number,
      }) {
        layout.value = l;
      },
      fullscreen() {
        if (camerasDiv.value) {
          camerasDiv.value.requestFullscreen();
        }
      },
    };
  },
});
